export enum SubscriptionPlans {
  "free" = "free",
  "patron" = "patron",
  "early_adopter" = "early_adopter",
}

export function humanizePlan(plan: SubscriptionPlans): string {
  switch (plan) {
    case SubscriptionPlans.free:
      return "Free";
    case SubscriptionPlans.patron:
      return "Patron";
    case SubscriptionPlans.early_adopter:
      return "Early Adopter";
  }
}

export const signedOutLives = 2;

// Mirrors Rails: app/concepts/stripes/constants/plans.rb
export const dailyLivesByPlan: {
  [plan in SubscriptionPlans]: {
    plan: SubscriptionPlans;
    lives: number;
    monthly_cost: string;
  };
} = {
  free: {
    plan: SubscriptionPlans.free,
    lives: 4,
    monthly_cost: "$0.00",
  },
  patron: {
    plan: SubscriptionPlans.patron,
    lives: 9,
    monthly_cost: "$2.50",
  },
  early_adopter: {
    plan: SubscriptionPlans.early_adopter,
    lives: Infinity,
    monthly_cost: "$7.99",
  },
};
