import { Difficulty } from "shared/games/Difficulty";
import qs from "query-string";
import { Game } from "shared/games/types/Game";
import Instrument from "shared/games/instruments/Instrument";
import {
  AllowedInstrumentPages,
  AllowedTechniquePages,
} from "app/ear-training/mixing/__helpers/getPageConfig";
import { slug } from "github-slugger";

export const homePage = "/";

/**
 * Marketing Pages
 */

export const marketingLandingPages = {
  vocalDriller: "/singing-practice-tool", // From a rewrite rule
  mixingGames: "/ear-training-for-mixers", // From a rewrite rule
};

export const blog = {
  index: (page?: number) => qs.stringifyUrl({ url: "/posts", query: { page } }),
  post: (slug: string) => `/posts/${slug}`,
  tag: (tag: string) => `/posts/tags/${slug(tag).toLowerCase()}`,
};

/**
 * Auth
 */
export const auth = {
  signup: ({ redirect_to }: { redirect_to?: string }) =>
    qs.stringifyUrl({ url: `/auth/signup`, query: { redirect_to } }),

  login: ({ redirect_to }: { redirect_to?: string }) =>
    qs.stringifyUrl({ url: "/auth/login", query: { redirect_to } }),
};

/**
 * Account
 */
export const account = {
  notificationManager: "/account/notifications",
  unsubscribe: "/account/unsubscribe",
};

/**
 * Ear Training Show Pages
 */

interface EarTrainingMixFreePlayParams {
  difficulty: Difficulty;
  games?: Game[];
  instruments?: Instrument[];
}

interface EarTrainingMixTodosQuestionParams {
  type: "todos";
  difficulty: Difficulty;
  games: Game[];
  back: string;
}

interface EarTrainingMixTodosQuestionsByIdParams {
  type: "by-id";
  todoIds: number[];
  back: string;
}

type EarTrainingMixTodosPageParams =
  | EarTrainingMixTodosQuestionParams
  | EarTrainingMixTodosQuestionsByIdParams;

export const earTraining = {
  mixing: {
    home: "/ear-training/mixing",

    todos: "/ear-training/mixing/todos",
    todosQueryParams(params: EarTrainingMixTodosPageParams) {
      if (params.type === "todos") {
        const { difficulty, games, back } = params;

        return qs.stringifyUrl({
          url: this.todos,
          query: {
            difficulty,
            games,
            back,
          },
        });
      }

      if (params.type === "by-id") {
        const { todoIds, back } = params;

        return qs.stringifyUrl(
          {
            url: this.todos,
            query: {
              todo_ids: todoIds,
              back,
            },
          },
          { arrayFormat: "none" }
        );
      }
    },

    freePlay: "/ear-training/mixing/free-play",
    freePlayQueryParams({
      difficulty,
      games,
      instruments,
    }: EarTrainingMixFreePlayParams) {
      return qs.stringifyUrl({
        url: this.freePlay,
        query: { difficulty, games, instruments },
      });
    },

    instruments: {
      show: (instrument: AllowedInstrumentPages) =>
        `/ear-training/mixing/instruments/${instrument}`,
    },
    techniques: {
      show: (technique: AllowedTechniquePages) =>
        `/ear-training/mixing/techniques/${technique}`,
    },
  },
};

export const singing = {
  vocalDriller: "/singing/vocal-driller",
  vocalDrillerShortUrl: (slug: string) =>
    `https://www.bedroomproducer.io/sp/${slug}`,
};

/**
 * Admin
 */

export const admin = {
  looop: {
    home: "/admin/looop",
    show: (id: number) => `/admin/looop/${id}`,
    preview: (slug: string) => `/mix-puzzles/admin/looops/${slug}`,
  },

  mixPuzzle: {
    home: "/admin/mix_puzzle",
    show: (id: number) => `/admin/mix_puzzle/${id}`,
    new: ({ loopSlug }: { loopSlug: string }) =>
      `/mix-puzzles/admin/looops/${loopSlug}/new`,
    preview: (slug: string) => `/mix-puzzles/admin/mix-puzzles/${slug}`,
    edit: ({ mixPuzzleSlug }: { mixPuzzleSlug: string }) =>
      `/mix-puzzles/admin/mix-puzzles/${mixPuzzleSlug}/edit`,
  },
};

/**
 * Mix Puzzles
 */

export const mixPuzzle = (slug: string, difficulty: Difficulty) =>
  qs.stringifyUrl({
    url: `/mix-puzzles/${slug}`,
    query: { difficulty },
  });
