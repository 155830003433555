import { User } from "../../shared/user/types";

/**
 * Types
 */

interface LoggedInAuthState {
  type: "loggedin";
  user: User;
  fetched: true;
  replaceUser: (user: User) => void;
  logout: () => void;
}

interface LoggedOutAuthState {
  type: "loggedout";
  user: null;
  fetched: true;
  loggedOutLivesRemainingToday: number;
}

export interface IndeterminateAuthState {
  type: "indeterminate";
  fetched: false;
}

export type AuthState =
  | LoggedInAuthState
  | LoggedOutAuthState
  | IndeterminateAuthState;

export function isIndeterminateAuthState(
  state: AuthState
): state is IndeterminateAuthState {
  return state.type === "indeterminate";
}

export function isLoggedIn(state: AuthState): state is LoggedInAuthState {
  return state.type === "loggedin";
}

export function isLoggedOut(state: AuthState): state is LoggedOutAuthState {
  return state.type === "loggedout";
}
