import classNames from "classnames";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { useEffect, useState } from "react";
import { white } from "tailwindcss/colors";
import { cat, a, shakeDuration, red500 } from "./constants";

interface Props {
  index: number;
  inOrOut: boolean;
  outline?: boolean;
}

export default function CatHead({ index, outline, inOrOut }: Props) {
  const [inOrOutState, setInOrOutState] = useState(true);

  useEffect(() => {
    setInOrOutState(inOrOut);
  }, [inOrOut]);

  return (
    <div className={"w-full"}>
      <div className={"relative aspect-w-1 aspect-h-1"}>
        <div key={index} className={classNames("absolute inset-0")}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={cat.viewBox}
            className="absolute inset-0"
          >
            <path
              className={classNames(
                outline
                  ? "fill-none stroke-gray-500 stroke-[3px]"
                  : "fill-gray-500"
              )}
              stroke="none"
              strokeDasharray={outline ? "7" : undefined}
              d={cat.d}
            />
          </svg>

          <AnimatePresence initial={false}>
            {!outline && (
              <motion.svg
                key={index}
                xmlns="http://www.w3.org/2000/svg"
                viewBox={cat.viewBox}
                className="absolute inset-0 -top-0.5"
                animate={inOrOutState ? a.in : a.out}
                variants={svgVariants}
                transition={{
                  type: "spring",
                  duration: shakeDuration,
                  bounce: 0.6,
                }}
              >
                <motion.path
                  key={index}
                  fill={white}
                  stroke="none"
                  d={cat.d}
                  transition={{ duration: shakeDuration, bounce: 1 }}
                  variants={{
                    [a.in]: { fill: white },
                    [a.out]: { fill: [red500, white] },
                  }}
                />
              </motion.svg>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

const svgVariants: Variants = {
  [a.in]: {
    opacity: 1,
    scale: 1,
    y: 0,
  },
  [a.out]: {
    opacity: 0,
    scale: [1.25, 1.25, 1.25, 1.25, 1.25, 1.25, 1.25, 1.25, 1.25, 0],
    rotate: [0, 25, -25, 5, -5, 2, -2, 0, 0, 0],
    x: [0, 0, 0, 5, -5, 3, -3, 1, -1, 0],
    transition: {
      duration: shakeDuration,
      bounce: 1,
      opacity: {
        delay: shakeDuration - 0.1,
        duration: 0.1,
      },
    },
  },
};
