import { format } from "date-fns";
import { useMemo } from "react";
import useLocalStorage from "util/useLocaleStorage";

const key = btoa("logged-out-lives-lost-tracker"); // btoa is used to obfuscate the key
const dateFormat = "yyyy-MM-dd";

interface LivesLostByDay {
  [key: string]: number;
}

function markLifeLost(previous: LivesLostByDay) {
  const today = format(new Date(), dateFormat);

  if (!previous[today]) {
    return {
      [today]: 1,
    };
  }

  return {
    ...previous,
    [today]: previous[today] + 1,
  };
}

export default function usePersistantTracker() {
  const [livesLostByDay, setLivesLostByDay] = useLocalStorage<LivesLostByDay>(
    key,
    {}
  );
  const livesLostToday = livesLostByDay[format(new Date(), dateFormat)] || 0;

  return useMemo(
    () => ({
      livesLostToday,
      markLifeLost() {
        setLivesLostByDay((prev) => markLifeLost(prev));
      },
    }),
    [livesLostToday, setLivesLostByDay]
  );
}
