export type WordType = "lives" | "minutes" | "both";

export function wordSingular(type: WordType) {
  if (type === "lives") {
    return "life";
  }

  if (type === "minutes") {
    return "minute";
  }

  if (type === "both") {
    return "life or minute";
  }

  throw new Error(`Invalid type ${type}`);
}

export function wordPlural(type: WordType) {
  if (type === "lives") {
    return "lives";
  }

  if (type === "minutes") {
    return "minutes";
  }

  if (type === "both") {
    return "lives or minutes";
  }

  throw new Error(`Invalid type ${type}`);
}
