import { createContext, ReactNode, useEffect, useRef, useState } from "react";
import { apiUserPath, ApiUserPathRes } from "apiRoutes/apiUserRoutes";
import useLoggedOutLiveTracker from "shared/user/lives/LoggedOutLiveTrackerContext/useLoggedOutLivesTracker";
import api, { isSuccess } from "../api";
import {
  IndeterminateAuthState,
  AuthState,
  isLoggedOut,
  isLoggedIn,
} from "./types";
import { User } from "shared/user/types";
import { homePage } from "routes";

const indterminateAuthState: IndeterminateAuthState = {
  type: "indeterminate",
  fetched: false,
};

export const Context = createContext<AuthState>(indterminateAuthState);

export function ContextProvider({ children }: { children: ReactNode }) {
  const [state, setState] = useState<AuthState>(indterminateAuthState);
  const { livesRemainingToday: loggedOutLivesRemainingToday } =
    useLoggedOutLiveTracker();

  const fetched = useRef(false);

  useEffect(() => {
    if (fetched.current) {
      return;
    }

    const fn = async () => {
      fetched.current = true;

      const user = await api<ApiUserPathRes>(apiUserPath);

      if (isSuccess(user)) {
        const replaceUser = (user: User) => {
          setState((prev) => {
            if (!isLoggedIn(prev)) {
              return prev;
            }

            return { ...prev, user };
          });
        };

        const logout = () => {
          // TODO: get this to work delete the remember_token cookie first
          // document.cookie =
          //   "remember_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

          // reload page
          window.location.href = homePage;
        };

        setState((prev) => ({
          ...prev,
          replaceUser,
          user: user.response,
          fetched: true,
          type: "loggedin",
          logout,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          user: null,
          fetched: true,
          type: "loggedout",
          loggedOutLivesRemainingToday,
        }));
      }
    };

    fn();
  }, [loggedOutLivesRemainingToday]);

  const loggedOut = isLoggedOut(state);

  useEffect(() => {
    if (!loggedOut) {
      return;
    }

    setState((prev) => ({
      ...prev,
      loggedOutLivesRemainingToday,
    }));
  }, [loggedOut, loggedOutLivesRemainingToday]);

  return <Context.Provider value={state}>{children}</Context.Provider>;
}
