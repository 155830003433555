import qs from "query-string";

/**
 * Form Post Portal
 */

export const apiStripePortalRoute = ({
  subscription_update,
  redirect_to,
}: {
  subscription_update?: true;
  redirect_to?: string;
}) =>
  qs.stringifyUrl({
    url: "/stripe/customer-portal",
    query: { subscription_update, redirect_to },
  });
