import classNames from "classnames";
import { motion } from "framer-motion";
import { dailyLivesByPlan } from "shared/user/plans";
import useAuth from "util/useAuth/useAuth";
import CatHead from "./CatHead";
import { getTitle } from "./getTitle";
import getDailyLives from "shared/user/lives/getDailyLives";
import getDailyLivesRemaining from "shared/user/lives/getDailyLivesRemaining";
import { isLoggedIn } from "util/useAuth/types";
import times from "util/times";
import { referenceTrackDailyLivesMeterId } from "ui/organisms/ReferenceTrack/referenceTrackIds";
import { WordType } from "./WordType";

interface Props {
  auth: ReturnType<typeof useAuth>;
  type: WordType;
  disableAnimation?: boolean;
  widthClassName?: string;
}

const mostLivesAUserCanHave = dailyLivesByPlan.patron.lives;
const gridTemplateColumnsStyle = {
  gridTemplateColumns: `repeat(${mostLivesAUserCanHave}, 1fr)`,
};

export default function DailyLivesMeter({
  auth,
  type,
  disableAnimation,
  widthClassName,
}: Props) {
  const livesRemaining = getDailyLivesRemaining(auth);

  return (
    <motion.div
      id={referenceTrackDailyLivesMeterId}
      className={classNames(
        "border-2 rounded bg-slate-300",
        widthClassName,
        livesRemaining <= 0 ? "border-red-500" : "border-slate-300"
      )}
      initial={
        disableAnimation || isLoggedIn(auth)
          ? undefined
          : { opacity: 0, y: -50 }
      }
      animate={disableAnimation ? undefined : { opacity: 1, y: 0 }}
      exit={disableAnimation ? undefined : { opacity: 0, y: -50 }}
    >
      <motion.div
        className={classNames(
          "whitespace-nowrap text-center text-xs font-brik",
          livesRemaining <= 0
            ? "bg-red-500 text-white"
            : "bg-slate-300 text-black"
        )}
      >
        {getTitle({ livesRemaining, type })}
      </motion.div>

      <div className={"py-1.5 px-2 bg-black"}>
        <PureCatsInARow auth={auth} />
      </div>
    </motion.div>
  );
}

interface PureCatsInARowProps {
  auth: ReturnType<typeof useAuth>;
}

export function PureCatsInARow({ auth }: PureCatsInARowProps) {
  const dailyLives = getDailyLives(auth);
  const livesRemaining = getDailyLivesRemaining(auth);

  return (
    <div
      className={classNames("w-full grid gap-2")}
      style={gridTemplateColumnsStyle}
    >
      {times(mostLivesAUserCanHave, (index) => (
        <CatHead
          key={index}
          index={index}
          inOrOut={
            typeof livesRemaining === "number" ? index < livesRemaining : false
          }
          outline={index >= dailyLives}
        />
      ))}
    </div>
  );
}
