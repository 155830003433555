import { useRouter } from "next/navigation";
import { useContext, useEffect } from "react";
import { Context } from "./ContextProvider";
import mixpanel from "mixpanel-browser";
import { isLoggedIn } from "./types";
import * as routes from "routes";

interface Props {
  requireAdmin?: boolean;
  redirectIfLoggedIn?: string;
  requireLoggedIn?: boolean;
}

export default function useAuth({
  requireAdmin,
  redirectIfLoggedIn,
  requireLoggedIn,
}: Props = {}) {
  const context = useContext(Context);
  const router = useRouter();
  const userId = isLoggedIn(context) ? context.user.id : null;

  useEffect(() => {
    if (!context.fetched) {
      return;
    }

    if (!userId) {
      return;
    }

    mixpanel.identify(userId.toString());
  }, [context.fetched, userId]);

  useEffect(() => {
    if (!context.fetched) {
      return;
    }

    if (!redirectIfLoggedIn) {
      return;
    }

    if (!context.user) {
      return;
    }

    router.replace(redirectIfLoggedIn);
  }, [context, context.fetched, redirectIfLoggedIn, router, userId]);

  // require logged in check
  useEffect(() => {
    if (!requireLoggedIn) {
      return;
    }

    if (!context.fetched) {
      return;
    }

    if (context.user) {
      return;
    }

    router.replace(routes.auth.login({}));
  }, [context, requireLoggedIn, router]);

  // require admin check
  useEffect(() => {
    if (!requireAdmin) {
      return;
    }

    if (!context.fetched) {
      return;
    }

    if (context.user?.admin) {
      return;
    }

    router.replace(routes.homePage);
  }, [context, requireAdmin, router]);

  return context;
}
