import { EllipsisVerticalIcon, UserIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import * as routes from "routes";
import { hasUnlimitedLives } from "shared/user/subscription";
import Link from "util/Link";
import {
  AuthState,
  isIndeterminateAuthState,
  isLoggedIn,
} from "util/useAuth/types";
import Cta from "./Cta";
import DailyLivesMeter from "./DailyLivesMeter";
import CatHeadWithNumberOverlayNavbarLink from "./CatHeadWithNumberOverlay";
import NavbarDropdownLink from "./NavbarDropdownLink";
import NavbarLink from "./NavbarLink";
import navbarTrack from "./navbarTrack";
import { UserOrEllipsisNavbarDropdownItems } from "./UserOrEllipsisNavbarDropdownItems";
import outOfLives from "shared/user/lives/outOfLives";
import { usePathname } from "next/navigation";

export type NavbarType = "normal" | "in-game";

export interface Props {
  auth: AuthState;
  maxWidthClassName?: string;
  activeLink?: "vocalDriller" | "earTraining";
  inGame?: boolean;
  inGameContextTitle?: string;
  redirectTo?: string;
}

export const iconClassName = "h-7 w-7";
const navbarHeightClassName = "h-16";
export const navbarHeightTopClassName = "t-16";
const marginRightClassName = "mr-2";
const navbarIngameHeightClassName = "h-24";

export default function Navbar({
  auth,
  maxWidthClassName = "max-w-6xl",
  activeLink,
  inGame,
  inGameContextTitle,
  redirectTo: passedInRedirectTo,
}: Props) {
  const hideLives = isIndeterminateAuthState(auth) || hasUnlimitedLives(auth);
  const isOutOfLives = outOfLives(auth);
  const pathname = usePathname();
  const redirectTo = passedInRedirectTo ?? pathname ?? routes.homePage;

  if (inGame) {
    return (
      <NavbarWrapper maxWidthClassName={maxWidthClassName} inGame={inGame}>
        <div className={"flex"}>
          <li className={"flex items-center"}>
            <Cta auth={auth} type="both" redirectTo={redirectTo}>
              {({ text }) => (
                <>
                  <div className={"relative z-0"}>
                    {!isOutOfLives && (
                      <div
                        className={
                          "absolute bg-yellow-500 text-black top-0 right-1 bottom-0 translate-x-[100%] text-[10px] leading-none font-bold -z-10 pl-1.5 p-1 rounded-r text-center"
                        }
                        style={{ writingMode: "vertical-lr" }}
                      >
                        {inGameContextTitle}
                      </div>
                    )}

                    <DailyLivesMeter
                      auth={auth}
                      type={"both"}
                      widthClassName={"w-[275px]"}
                    />
                  </div>

                  <div className={"mt-1 text-xs text-center leading-none"}>
                    {text}
                  </div>
                </>
              )}
            </Cta>
          </li>
        </div>

        <li className={"flex items-center mb-4"}>
          <UserOrEllipsisDropdown
            auth={auth}
            forceEllipsis
            redirectTo={redirectTo}
          />
        </li>
      </NavbarWrapper>
    );
  }

  return (
    <NavbarWrapper maxWidthClassName={maxWidthClassName}>
      <ul className={"flex items-center mr-2"}>
        {/* Home Button */}
        <li className={"flex items-center"}>
          <Link
            href={routes.homePage}
            onClick={navbarTrack({ href: routes.homePage })}
          >
            <div className={"bg-red-500 h-6 w-6 rounded-full"} />
          </Link>
        </li>

        <VerticalNavSpacer hideMobile horizontalMarginClassName={"mx-2"} />

        {/* Vocal Driller Link */}
        <NavbarLink
          text="Vocal Driller"
          href={routes.singing.vocalDriller}
          active={activeLink === "vocalDriller"}
          hideMobile
          marginRightClassName={marginRightClassName}
        />

        {/* Ear Training Link */}
        <NavbarLink
          text="Ear Training"
          href={routes.earTraining.mixing.home}
          active={activeLink === "earTraining"}
          hideMobile
        />
      </ul>

      <ul className={"flex items-center flex-1 justify-end"}>
        {/* Single Cat Icon */}
        {!hideLives && (
          <CatHeadWithNumberOverlayNavbarLink
            iconClassName={iconClassName}
            auth={auth}
            marginRightClassName={marginRightClassName}
          />
        )}

        {/* User / Ellipsis Dropdown */}
        <UserOrEllipsisDropdown auth={auth} redirectTo={redirectTo} />
      </ul>
    </NavbarWrapper>
  );
}

function NavbarWrapper({
  children,
  maxWidthClassName,
  inGame,
}: {
  children: React.ReactNode;
  maxWidthClassName?: string;
  inGame?: boolean;
}) {
  return (
    <nav
      className={classNames(
        inGame ? navbarIngameHeightClassName : navbarHeightClassName,
        !inGame && "border-b-2 border-white ",
        "bg-slate-950 text-white px-2 z-40 relative"
      )}
    >
      <ul
        className={classNames(
          "h-full mx-auto flex justify-between",
          maxWidthClassName
        )}
      >
        {children}
      </ul>
    </nav>
  );
}

function VerticalNavSpacer({
  horizontalMarginClassName,
  hideMobile,
}: {
  horizontalMarginClassName: string;
  hideMobile?: boolean;
}) {
  return (
    <div
      className={classNames(
        "w-[1px] bg-white h-[30px]",
        horizontalMarginClassName,
        hideMobile ? "hidden md:inline-block" : ""
      )}
    />
  );
}

function UserOrEllipsisDropdown({
  forceEllipsis,
  auth,
  redirectTo,
}: {
  forceEllipsis?: boolean;
  auth: AuthState;
  redirectTo: string;
}) {
  const loggedIn = isLoggedIn(auth);

  return (
    <NavbarDropdownLink
      dropdownClassName="p-3"
      buttonClassName={"relative"}
      buttonChildren={
        <div
          className={classNames(
            "bg-white text-black rounded-full p-0.5",
            iconClassName
          )}
        >
          {!forceEllipsis && loggedIn ? (
            <UserIcon className={"w-full"} />
          ) : (
            <EllipsisVerticalIcon className={"w-full"} />
          )}
        </div>
      }
    >
      <UserOrEllipsisNavbarDropdownItems auth={auth} redirectTo={redirectTo} />
    </NavbarDropdownLink>
  );
}
