import mixpanel from "mixpanel-browser";
import * as routes from "routes";
import * as stripePortal from "apiRoutes/apiStripeRoutes";
import { upgradeAd } from "shared/analytics/mixpanel/adEvents";
import outOfLives from "shared/user/lives/outOfLives";
import { dailyLivesByPlan } from "shared/user/plans";
import { getNextUpgrade } from "shared/user/subscription";
import { AuthState, isIndeterminateAuthState } from "util/useAuth/types";
import { Cta as CtaShape } from "./constants";
import Tag from "./Tag";
import { wordPlural, WordType } from "./WordType";

interface Props {
  auth: AuthState;
  type: WordType;
  className?: string;
  formClassName?: string;
  children: (props: { text: string; shortText: string }) => React.ReactNode;
  redirectTo?: string;
}

export default function Cta({
  auth,
  type,
  className,
  children,
  formClassName,
  redirectTo,
}: Props) {
  const cta = getCta(auth, type, redirectTo);

  if (!cta) {
    return null;
  }

  return (
    <Tag
      formClassName={formClassName}
      cta={cta}
      className={className}
      onClick={() => {
        mixpanel.track(upgradeAd.event, {
          [upgradeAd.properties.location]:
            type === "lives" ? "Daily Lives Meter" : "Daily Minutes Meter",
          [upgradeAd.properties.nextUpgrade]: getNextUpgrade(auth),
        });
      }}
    >
      {children({ text: cta.text, shortText: "Get more lives →" })}
    </Tag>
  );
}

function getCta(
  auth: AuthState,
  type: Props["type"],
  redirectTo?: string
): CtaShape {
  if (isIndeterminateAuthState(auth)) {
    return null;
  }

  const nextUpgrade = getNextUpgrade(auth);

  if (nextUpgrade === null) {
    return null;
  }

  const planInfo = dailyLivesByPlan[nextUpgrade];
  const lives = wordPlural(type);

  if (nextUpgrade === "free") {
    if (outOfLives(auth)) {
      return {
        text: `Sign up now to get ${planInfo.lives} more ${lives} free! →`,
        href: routes.auth.signup({ redirect_to: redirectTo }),
      };
    }

    return {
      text: `Sign up free to get ${planInfo.lives} daily ${lives}! →`,
      href: routes.auth.signup({ redirect_to: redirectTo }),
    };
  }

  if (nextUpgrade === "patron") {
    return {
      text: `Upgrade to ${planInfo.lives} ${lives} for ${planInfo.monthly_cost}/month! →`,
      formUrl: stripePortal.apiStripePortalRoute({
        subscription_update: true,
        redirect_to: redirectTo,
      }),
    };
  }

  if (nextUpgrade === "early_adopter") {
    return {
      text: `Unlimited ${lives} for ${planInfo.monthly_cost}/month! →`,
      formUrl: stripePortal.apiStripePortalRoute({
        subscription_update: true,
        redirect_to: redirectTo,
      }),
    };
  }

  return null;
}
