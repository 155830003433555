import { useLayer, Arrow, useHover } from "react-laag";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { gray } from "tailwindcss/colors";

interface Props {
  children: React.ReactNode;
  buttonChildren: React.ReactNode;
  buttonClassName?: string;
  dropdownClassName?: string;
}

export default function NavbarDropdownLink({
  children,
  buttonChildren,
  buttonClassName,
  dropdownClassName,
}: Props) {
  const [isOver, hoverProps] = useHover({ delayEnter: 200, delayLeave: 150 });
  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen: isOver,
    overflowContainer: false,
    auto: true,
    placement: "bottom-center",
    possiblePlacements: ["bottom-center", "top-center"],
    triggerOffset: 12,
    containerOffset: 16,
    arrowOffset: 7,
  });

  return (
    <>
      <button {...triggerProps} {...hoverProps} className={buttonClassName}>
        {buttonChildren}
      </button>

      {renderLayer(
        <AnimatePresence>
          {isOver && (
            <motion.ul
              {...layerProps}
              {...hoverProps}
              initial={{ opacity: 0, y: 5 }}
              animate={{
                opacity: 1,
                y: 0,
                WebkitTransform: "translate3d(0,0,0)", // This is a hack to fix a bug with Safari mobile only zindex
              }}
              exit={{ opacity: 0, y: 5 }}
              transition={{ duration: 0.3 }}
              className={classNames(
                "bg-slate-950 text-white border border-gray-500 shadow-xl rounded-xl z-50 translate-x-0",
                dropdownClassName
              )}
            >
              <Arrow {...arrowProps} backgroundColor={gray[500]} />
              {children}
            </motion.ul>
          )}
        </AnimatePresence>
      )}
    </>
  );
}
