import {
  ArrowRightOnRectangleIcon,
  BellIcon,
  BookOpenIcon,
  HomeIcon,
  PencilSquareIcon,
  UserIcon,
  UserPlusIcon,
} from "@heroicons/react/24/solid";
import * as routes from "routes";
import * as stripePortal from "apiRoutes/apiStripeRoutes";
import Link from "util/Link";
import { AuthState, isLoggedIn } from "util/useAuth/types";
import Divider from "ui/atoms/Divider";
import navbarTrack from "./navbarTrack";

interface Props {
  auth: AuthState;
  redirectTo: string;
}

export function UserOrEllipsisNavbarDropdownItems({ auth, redirectTo }: Props) {
  const linkClassName =
    "py-2 px-2 hover:bg-purple-500 rounded flex items-center transition-all w-full";

  return (
    <>
      {isLoggedIn(auth) ? (
        <>
          <li>
            <form
              action={stripePortal.apiStripePortalRoute({
                subscription_update: true,
                redirect_to: redirectTo,
              })}
              method="POST"
              className={"flex items-center"}
            >
              <button type="submit" className={linkClassName}>
                <div className={"flex items-center"}>
                  <PencilSquareIcon className={"h-6 mr-1"} /> Manage Plan
                </div>
              </button>
            </form>
          </li>

          <li>
            <Link
              className={linkClassName}
              href={routes.account.notificationManager}
              onClick={navbarTrack({
                href: routes.account.notificationManager,
              })}
            >
              <div className={"flex items-center"}>
                <BellIcon className={"h-6 mr-1"} /> Notifications
              </div>
            </Link>
          </li>

          {/* TODO: implement logout */}
        </>
      ) : (
        <>
          <li>
            <Link
              className={linkClassName}
              href={routes.auth.signup({})}
              onClick={navbarTrack({
                href: routes.auth.signup({ redirect_to: redirectTo }),
              })}
            >
              <div className={"flex items-center"}>
                <UserPlusIcon className={"h-6 mr-1"} /> Create Account
              </div>
            </Link>
          </li>

          <li>
            <Link
              className={linkClassName}
              href={routes.auth.login({})}
              onClick={navbarTrack({
                href: routes.auth.login({ redirect_to: redirectTo }),
              })}
            >
              <div className={"flex items-center"}>
                <UserIcon className={"h-6 mr-1"} /> Login
              </div>
            </Link>
          </li>
        </>
      )}

      <>
        <Divider className={"my-2"} />

        <li>
          <Link
            href={routes.homePage}
            className={linkClassName}
            onClick={navbarTrack({ href: routes.homePage })}
          >
            <HomeIcon className={"h-6 mr-1"} />
            Home
          </Link>
        </li>

        <li>
          <Link
            href={routes.blog.index()}
            className={linkClassName}
            onClick={navbarTrack({ href: routes.blog.index() })}
          >
            <BookOpenIcon className={"h-6 mr-1"} />
            Learning Center
          </Link>
        </li>

        <li>
          <Link
            href={routes.earTraining.mixing.home}
            className={linkClassName}
            onClick={navbarTrack({ href: routes.earTraining.mixing.home })}
          >
            <ArrowRightOnRectangleIcon className={"h-6 mr-1"} />
            Ear Training
          </Link>
        </li>

        <li>
          <Link
            href={routes.singing.vocalDriller}
            className={linkClassName}
            onClick={navbarTrack({ href: routes.singing.vocalDriller })}
          >
            <ArrowRightOnRectangleIcon className={"h-6 mr-1"} />
            Vocal Driller
          </Link>
        </li>
      </>
    </>
  );
}
