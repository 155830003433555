import classNames from "classnames";
import css from "./Divider.module.css";

interface Props {
  className?: string;
}

export default function Divider({ className }: Props) {
  return <hr className={classNames(css.customBorder, className)} />;
}
