import mixpanel from "mixpanel-browser";
import { click } from "shared/analytics/mixpanel/clickEvents";

export default function navbarTrack({ href }: { href: string }) {
  return () =>
    mixpanel.track(click.event, {
      [click.properties.label]: href,
      [click.properties.page]: "Navbar",
    });
}
