export const slate300 = "rgb(203, 213, 225)";

export const red500 = "rgb(239, 68, 68)";

export const shakeDuration = 5;

export const a = {
  in: "in",
  out: "out",
};

export const cat = {
  viewBox: "0 0 50 52",
  d: "M1 32c0 9.5 15 19 24 19 7.5 0 24-9 24-20 0-12-5.5-13-5.5-14.5-1.5-8-2.5-14.471-4-15-1.5-.529-9 11.5-10.5 14-.12.198-8.835.255-9 0C14.5 7 11 2 9.5 1.5c-2 1-4 16.5-4 16.5S1 19.5 1 32Z",
};

export type Cta =
  | { text: string; formUrl: string }
  | { text: string; href: string }
  | null;
