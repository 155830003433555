import { useMountEffect } from "@react-hookz/web";
import { isBrowser } from "@react-hookz/web/cjs/util/const";
import { Dispatch, SetStateAction, useCallback, useState } from "react";

const storage = (type: "session" | "local") =>
  type === "session" ? window.sessionStorage : window.localStorage;

export default function useLocalStorage<T>(
  key: string,
  initialValue: T,
  type: "session" | "local" = "local"
) {
  const [fetched, setFetched] = useState(false);
  const safeFetch = () => {
    try {
      const item = storage(type).getItem(key);

      if (!item) {
        return initialValue;
      }

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {}
  };

  const [storedValue, setStoredValue] = useState<T>(initialValue);

  const updater: Dispatch<SetStateAction<T>> = useCallback(
    (value: T | ((prev: T) => T)) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;

        setStoredValue(valueToStore);

        storage(type).setItem(key, JSON.stringify(valueToStore));
      } catch (error) {}
    },
    [key, storedValue, type]
  );

  useMountEffect(() => {
    if (!isBrowser) {
      return;
    }

    const item = safeFetch();

    setFetched(true);

    if (!item) {
      return;
    }

    setStoredValue(item);
  });

  return [storedValue, updater, fetched] as const;
}
