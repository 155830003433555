import { ReactNode } from "react";
import { Cta } from "./constants";

interface Props {
  children: ReactNode;
  cta: Cta;
  className?: string;
  formClassName?: string;
  onClick?: () => void;
}

export default function Tag({
  children,
  cta,
  className,
  onClick,
  formClassName,
}: Props) {
  if (!cta) {
    return null;
  }

  if ("href" in cta) {
    return (
      <a href={cta.href} className={className} onClick={onClick}>
        {children}
      </a>
    );
  }

  return (
    <form action={cta.formUrl} method="POST" className={formClassName}>
      <button className={className} type="submit" onClick={onClick}>
        {children}
      </button>
    </form>
  );
}
