import classNames from "classnames";
import getDailyLivesRemaining from "shared/user/lives/getDailyLivesRemaining";
import { AuthState } from "util/useAuth/types";
import CatHead from "./CatHead";
import Cta from "./Cta";

interface Props {
  iconClassName: string;
  auth: AuthState;
  marginRightClassName?: string;
}

export default function CatHeadWithNumberOverlay({
  iconClassName,
  auth,
  marginRightClassName,
}: Props) {
  const livesRemaining = getDailyLivesRemaining(auth);
  const theme =
    livesRemaining <= 0
      ? {
          front: "fill-red-500",
          shadow: "fill-red-700",
        }
      : {
          front: "fill-white",
          shadow: "fill-gray-500",
        };

  return (
    <Cta
      formClassName={"flex items-center"}
      className={marginRightClassName}
      auth={auth}
      type="both"
    >
      {({ text }) => (
        <div className={"flex items-center"}>
          <div className={"text-xs text-right mr-2 leading-none"}>
            <div className="max-w-[175px]">{text}</div>
          </div>

          <div className={classNames("relative", iconClassName)}>
            <CatHead inOrOut={livesRemaining > 0} key={0} index={0} />

            <div
              className={
                "absolute inset-0 leading-none text-black flex items-center justify-center font-brik"
              }
            >
              <div className={"mt-1.5"}>{livesRemaining}</div>
            </div>
          </div>
        </div>
      )}
    </Cta>
  );
}
