import {
  AuthState,
  isIndeterminateAuthState,
  isLoggedOut,
} from "util/useAuth/types";
import { SubscriptionPlans } from "./plans";

export function hasUnlimitedLives(auth: AuthState) {
  if (isIndeterminateAuthState(auth)) {
    return true;
  }

  if (isLoggedOut(auth)) {
    return false;
  }

  return auth.user.plan === SubscriptionPlans.early_adopter;
}

export function getNextUpgrade(auth: AuthState): SubscriptionPlans | null {
  if (isIndeterminateAuthState(auth)) {
    return null;
  }

  if (isLoggedOut(auth)) {
    return SubscriptionPlans.free;
  }

  if (auth.user.plan === SubscriptionPlans.free) {
    return SubscriptionPlans.patron;
  }

  if (auth.user.plan === SubscriptionPlans.patron) {
    return SubscriptionPlans.early_adopter;
  }

  return null;
}
