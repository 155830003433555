import {
  AuthState,
  isIndeterminateAuthState,
  isLoggedOut,
} from "util/useAuth/types";
import { dailyLivesByPlan } from "../plans";

export default function getDailyLivesRemaining(auth: AuthState) {
  if (isIndeterminateAuthState(auth)) {
    return dailyLivesByPlan.early_adopter.lives;
  }

  const lives = isLoggedOut(auth)
    ? auth.loggedOutLivesRemainingToday
    : auth.user.daily_lives_remaining;

  return lives >= 0 ? lives : 0;
}
