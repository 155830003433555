import NextLink, { LinkProps } from "next/link";
import { forwardRef, useCallback } from "react";

// Copid from next/link types
type Props = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  keyof LinkProps
> &
  LinkProps & {
    children?: React.ReactNode;
  } & React.RefAttributes<HTMLAnchorElement>;

export default forwardRef<HTMLAnchorElement, Props>(function Link(props, ref) {
  const { onClick: originalOnClick } = props;

  // Wrap onClick to start NProgress since Next.js Router events don't fire with the new app folder
  const onClick: LinkProps["onClick"] = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      originalOnClick?.(e);
    },
    [originalOnClick]
  );

  return <NextLink {...props} ref={ref} onClick={onClick} />;
});
