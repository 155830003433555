import { createContext, useMemo } from "react";
import { signedOutLives } from "shared/user/plans";
import usePersistantTracker from "./usePersistantTracker";

interface State {
  livesRemainingToday: number;
  markLifeLost: () => void;
}

export const Context = createContext<State>({
  livesRemainingToday: signedOutLives,
  markLifeLost: () => {},
});

export function LoggedOutLiveTrackerProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { livesLostToday, markLifeLost } = usePersistantTracker();
  const value: State = useMemo(
    () => ({
      livesRemainingToday: signedOutLives - livesLostToday,
      markLifeLost,
    }),
    [livesLostToday, markLifeLost]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
