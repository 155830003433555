import classNames from "classnames";
import Link from "util/Link";
import navbarTrack from "./navbarTrack";

interface Props {
  text: string;
  href: string;
  active?: boolean;
  hideMobile?: boolean;
  marginRightClassName?: string;
}

export default function NavbarLink({
  text,
  href,
  active,
  hideMobile,
  marginRightClassName,
}: Props) {
  return (
    <Link
      href={href}
      className={classNames(
        "font-bold flex items-center whitespace-nowrap border border-white/25 rounded-full px-3 py-0.5",
        active
          ? "text-white bg-gradient-to-br from-purple-500 to-purple-600 border-purple-500/50"
          : "text-white",
        hideMobile && "hidden md:flex",
        marginRightClassName
      )}
      onClick={navbarTrack({ href })}
    >
      {text}
    </Link>
  );
}
