import { wordPlural, wordSingular, WordType } from "./WordType";

interface Params {
  livesRemaining: number | null;
  type: WordType;
}

export function getTitle({ livesRemaining, type }: Params) {
  if (livesRemaining === 0) {
    return `Out of ${wordPlural(type)} for the day!`;
  }

  const life = livesRemaining === 1 ? wordSingular(type) : wordPlural(type);

  return `${livesRemaining} daily ${life} remaining`;
}
